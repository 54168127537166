<template>
  <div v-loading="loading" class="order-menu">
    <div class="is-flex is-justify-content-space-between mt-2 pl-4 pr-3">
      <div class="title is-flex">
        <h2 class="is-size-3 has-text-centered has-text-weight-semibold">Меню</h2>
        <el-button
          size="small"
          type="danger"
          style="height: 30px"
          class="mt-1 ml-5"
          @click="clearOrder"
        >
          Сбросить заказ
        </el-button>
      </div>

      <div v-if="menu" class="button-container is-flex is-flex-direction-column">
        <el-button
          size="small"
          type="success"
          icon="el-icon-check"
          style="height: 30px"
          @click="sendOrder"
        >
          Подтвердить
        </el-button>
        <el-input
          v-model="menu.sale"
          size="mini"
          placeholder="Cкидка"
          style="width: 150px"
          class="mt-4 mb-4"
        >
          <template slot="append">%</template>
        </el-input>
      </div>
    </div>
    <div class="pl-4 pt-4">Общая сумма заказа: {{ allSum }} ₽</div>
    <div v-if="menu">
      <div class="is-flex pr-3 pl-4 is-justify-content-space-between">
        <div class="sum">
          <p>Сумма заказа: {{ sum }} ₽</p>
        </div>
        <div class="longer">
          <el-checkbox v-model="isLonger"> Пролонгировать </el-checkbox>
        </div>
      </div>
      <div v-if="isLonger" class="is-flex is-flex-direction-column pr-3 pl-4 mt-4">
        <p class="mb-3">Сумма заказа с лонгированием: {{ longSum }} ₽</p>
        <el-date-picker
          v-model="dateLonger"
          style="width: 100%"
          type="date"
          placeholder="Выберите дату"
          value-format="yyyy-MM-dd"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="checkSumLonger"
        />
      </div>
      <div class="is-flex pr-3 pl-4 is-justify-content-space-between mt-2">
        <el-tabs>
          <el-tab-pane label="Основное меню">
            <div class="overflow all-menu">
              <menu-item
                v-for="item in positions"
                :key="item.cycleDataId"
                class="menu-card mb-4 is-flex"
                :menu="item"
                :class="{ selected: item.isSelected }"
                @selectMenu="selectPosition"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="Дополнительное меню">
            <div class="overflow all-menu">
              <menu-item
                v-for="addition in additions"
                :key="`addition-${addition.date}-${addition.id}`"
                class="menu-card mb-4 is-flex"
                :menu="{ ...addition }"
                :class="{ selected: addition.isSelected }"
                @selectMenu="selectAddition"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-else class="centered">
      <h2 v-if="!menu" class="is-size-4 has-text-centered has-text-weight-regular">
        Ничего не выбранно
      </h2>
      <h2 v-else class="is-size-4 has-text-centered has-text-weight-regular">Ничего не найдено</h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { MENU_LOADING, MENU } from '@/app/administration/cycles/shared/state/types/getter_types';
import {
  STORE_ORDERS,
  FETCH_ORDER,
  FETCH_LONGER,
  UPDATE_ORDERS,
  DELETE_ORDER,
} from '@/app/clients/shared/state/types/action_types';
import { DATA, LONGER_SUM } from '@/app/clients/shared/state/types/getter_types';
import menuItem from './menu-item.vue';

export default {
  name: 'ClientOrdersMenu',
  components: { menuItem },
  data() {
    return {
      dateLonger: null,
      additionsPositions: [],
      selectedPositions: [],
      isLonger: false,
      selectedMenu: {},
    };
  },
  computed: {
    ...mapGetters({
      menu: MENU,
      loading: MENU_LOADING,
      orders: DATA,
      longerSum: LONGER_SUM,
    }),
    ...mapState({
      orderss: (state) => state.clientsState.clientsData.orders_cal.data,
    }),
    dateOrder() {
      return this.menu.date;
    },
    order() {
      return {
        date: this.dateOrder,
        sale: this.menu.sale,
        client_id: +this.$route.params.id,
        positions: this.selectedPositions,
        additions: this.additionsPositions,
        longer: this.isLonger ? this.dateLonger : null,
      };
    },
    allSum() {
      const sumPositions = this.orderss.reduce((acc, current) => acc + parseInt(current.price), 0);
      return sumPositions;
    },
    sum() {
      const sumPositions = this.selectedPositions.reduce(
        (acc, current) => acc + parseInt(current.price) * current.quantity,
        0
      );
      const sumAdditions = this.additionsPositions.reduce(
        (acc, current) => acc + parseInt(current.price) * current.quantity,
        0
      );
      const saleAmount = (100 - this.order.sale) / 100;
      return (sumPositions * saleAmount + sumAdditions).toFixed(2);
    },
    longSum() {
      const sum = +this.longerSum + +this.sum;
      return typeof sum === 'number' ? sum.toFixed(2) : 0;
    },
    positions() {
      if (this.menu.positions) {
        return this.menu.positions;
      }
      return [];
    },
    additions() {
      if (this.menu.additions) {
        return this.menu.additions;
      }
      return [];
    },
  },
  watch: {
    menu(value) {
      if (value.positions.length > 0) {
        this.selectedPositions = value.positions
          .filter((position) => position.isSelected)
          .map((position) => ({
            cycleDataId: position.cycleDataId,
            quantity: position.col ?? 0,
            price: +position.price ?? 0,
            sale: position.sale ?? 0,
          }));
      }
      if (value.additions.length > 0) {
        this.additionsPositions = value.additions
          .filter((addition) => addition.isSelected)
          .map((addition) => ({
            id: addition.id,
            quantity: addition.col ?? 0,
            price: +addition.price ?? 0,
            sale: +addition.sale ?? 0,
          }));
      }
    },
  },
  mounted() {
    this.$store.dispatch('FETCH_ADDITIONS');
  },
  methods: {
    ...mapActions({
      store: STORE_ORDERS,
      update: UPDATE_ORDERS,
      fetchOrder: FETCH_ORDER,
      fetchSum: FETCH_LONGER,
      clearOrders: DELETE_ORDER,
    }),
    async checkSumLonger() {
      if (this.dateLonger) {
        await this.fetchSum({
          action: 'price',
          date: this.dateLonger,
          from: this.dateOrder,
          sale: this.menu.sale,
          positions: JSON.stringify(this.order.positions),
          additions: JSON.stringify(this.order.additions),
        });
      }
    },
    selectPosition(item) {
      if (
        item.isSelected &&
        !this.selectedPositions.find((s) => s.cycleDataId === item.cycleDataId)
      ) {
        this.selectedPositions.push({
          cycleDataId: item.cycleDataId,
          quantity: item.col ?? 0,
          price: +item.price ?? 0,
          sale: +item.sale ?? 0,
        });
        this.checkSumLonger();
        return;
      }

      if (
        item.isSelected &&
        this.selectedPositions.find((s) => s.cycleDataId === item.cycleDataId)
      ) {
        const selectedPositionIndex = this.selectedPositions.findIndex(
          (s) => s.cycleDataId === item.cycleDataId
        );
        this.selectedPositions[selectedPositionIndex].quantity = +item.col ?? 0;
        this.selectedPositions[selectedPositionIndex].sale = +item.sale ?? 0;
        this.checkSumLonger();
        return;
      }

      if (
        !item.isSelected &&
        this.selectedPositions.find((s) => s.cycleDataId === item.cycleDataId)
      ) {
        const selectedPositionIndex = this.selectedPositions.findIndex(
          (s) => s.cycleDataId === item.cycleDataId
        );
        this.selectedPositions[selectedPositionIndex].quantity = 0;
        this.checkSumLonger();
      }
    },
    selectAddition(item) {
      const itemAddition = { sale: 0, ...item };
      if (item.isSelected && !this.additionsPositions.find((s) => s.id === item.id)) {
        this.additionsPositions.push({
          id: itemAddition.id,
          quantity: itemAddition.col ?? 0,
          price: +itemAddition.price * (1 - (+itemAddition.sale ?? 0) / 100),
          sale: +itemAddition.sale ?? 0,
        });
        this.checkSumLonger();
        return;
      }

      if (item.isSelected && this.additionsPositions.find((s) => s.id === item.id)) {
        const additionsPositionsIndex = this.additionsPositions.findIndex((s) => s.id === item.id);
        this.additionsPositions[additionsPositionsIndex].quantity = +itemAddition.col ?? 0;
        this.additionsPositions[additionsPositionsIndex].sale = +itemAddition.sale ?? 0;
        this.additionsPositions[additionsPositionsIndex].price =
          +itemAddition.price * (1 - (+itemAddition.sale ?? 0) / 100);
        this.checkSumLonger();
        return;
      }

      if (!item.isSelected && this.additionsPositions.find((s) => s.id === item.id)) {
        const additionsPositionsIndex = this.additionsPositions.findIndex((s) => s.id === item.id);
        this.additionsPositions[additionsPositionsIndex].quantity = 0;
        this.additionsPositions[additionsPositionsIndex].sale = 0;
        this.checkSumLonger();
      }
    },
    async sendOrder() {
      await this.store(this.order);
    },
    async clearOrder() {
      await this.clearOrders(this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scope>
.image {
  width: 100%;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.time {
  color: #999;
}
.overflow {
  overflow-y: overlay;
  max-height: 80vh;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.image-container {
  height: 100%;
  width: 100%;
  padding: 5px;
}
.menu-card {
  width: 90%;
  cursor: pointer;
  margin: 0 auto;
  border: 1px solid #ccc;
  &:hover {
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  }
  &:first-child {
    margin-top: 20px;
  }
  &--body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .center-text {
      line-height: 1.2;
      padding-top: 15px;
    }
    .bottom {
      width: 100%;
    }
  }
}
.el-badge__content.is-dot {
  width: 20px;
  height: 20px;
  border-radius: 0;
}
.el-badge__content.is-fixed.is-dot {
  right: 19px;
  top: 8px;
}
.center-text {
  margin-top: 5px;
}
.selected {
  box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.6) inset;
}
</style>
