<template>
  <div v-if="loadedEvents" class="order-calendar">
    <vue-cal
      v-loading="loading"
      style="height: 80vh"
      :time="false"
      locale="ru"
      active-view="month"
      :min-date="cycle.start"
      :max-date="cycle.end"
      :events="events"
      :disable-views="['years', 'year', 'week', 'day']"
      :events-on-month-view="true"
      :show-all-day-events="true"
      :on-event-click="clickEventFn"
      @cell-dblclick="clickAddEventFn"
      @cell-click="clickShowMenuFn"
    />
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import { mapGetters, mapActions, mapState } from 'vuex';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/ru';
import moment from 'moment';
import {
  FETCH_CYCLE_CURRENT,
  FETCH_MENU,
} from '@/app/administration/cycles/shared/state/types/action_types';
import { FETCH_ORDERS, FETCH_ORDER } from '@/app/clients/shared/state/types/action_types';
import {
  CYCLE_CURRENT,
  LOADING,
} from '@/app/administration/cycles/shared/state/types/getter_types';

export default {
  name: 'ClientOrdersCalendar',
  components: { VueCal },
  data() {
    return {
      modal: {
        show: false,
        title: 'Добавить заказ',
      },
      position: {},
    };
  },
  computed: {
    ...mapGetters({ cycle: CYCLE_CURRENT, loading: LOADING }),
    ...mapState({
      orders: (state) => state.clientsState.clientsData.orders_cal,
    }),
    events() {
      return this.orders ? this.orders.data : [];
    },
    loadedEvents() {
      return this.orders && this.cycle ? this.orders.data.length >= 0 : false;
    },
  },
  async created() {
    await this.fetchOrders({ client: this.$route.params.id });
    await this.fetchCurrent();
  },
  methods: {
    ...mapActions({
      fetchCurrent: FETCH_CYCLE_CURRENT,
      fetchOrders: FETCH_ORDERS,
      fetchOrder: FETCH_ORDER,
      fetchMenu: FETCH_MENU,
    }),
    async clickShowMenuFn(d) {
      const date = moment(d).format('YYYY-MM-DD');
      const client = this.$route.params.id;
      await this.fetchMenu({ date, client });
    },
    async clickEventFn(d) {
      const date = moment(d.start).format('YYYY-MM-DD');
      await this.fetchOrder({ date, client: this.$route.params.id });
    },
    async clickAddEventFn(e) {
      this.selectedDay = moment(e).format('YYYY-MM-DD');
      await this.$store.dispatch('FETCH_ORDER_BY_DATE', {
        date: this.selectedDay,
        client: this.$route.params.id,
      });
      this.modal.show = true;
      this.position = {
        date: this.selectedDay,
        client_id: this.$route.params.id,
      };
    },
  },
};
</script>

<style lang="scss">
.add-cycle {
  margin-bottom: 1rem;
}
.vuecal__cell:not(.vuecal__cell--disabled) {
  background-color: rgba(#409eff, 0.2);
}
.food-events {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 13px;
  i {
    color: #f56c6c;
  }
  .count-food {
    color: #606266;
  }
  .content-events {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}
.vuecal__event {
  background: transparent;
}
.vuecal__cell-events {
  height: 100%;
  position: absolute;
  background: transparent;
}
.vuecal__cell--selected {
  background-color: #e4e7ed !important;
}
</style>
