<template>
  <div>
    <el-badge
      :hidden="!menuItem.isSelected"
      value="Выбрано"
      class="item"
      type="success"
      :is-dot="true"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="image-container" @click="clickMe">
            <img
              :src="menuItem.image ? menuItem.image : require('@/assets/placeholder.png')"
              class="image"
            />
          </div>
        </el-col>
        <el-col :span="16">
          <div class="menu-card--body">
            <div class="title">
              <el-link :underline="false" class="center-text" @click="clickMe">
                {{ menuItem.title }}
              </el-link>
            </div>
            <div
              class="bottom is-flex"
              :class="{
                'is-justify-content-space-around': menuItem.meal,
                'is-justify-content-flex-start': !menuItem.meal,
                'pr-4': !menuItem.meal,
              }"
            >
              <span v-if="menuItem.meal" class="time">
                <i class="el-icon-price-tag mr-2" />
                {{ menuItem.meal }}
              </span>
              <span class="price">
                <i class="el-icon-money mr-2" />
                {{ menuItem.price }}
              </span>
            </div>
            <div :class="{ 'mb-4': !menu.meal, 'mt-3': !menuItem.meal }">
              <el-input
                v-model="menuItem.col"
                style="width: 95%"
                class="mt-2 mb-2"
                placeholder="Кол-во"
                size="small"
                @input="changeCol"
              />
            </div>
            <div :class="{ 'mb-4': !menu.meal, 'mt-3': !menuItem.meal }">
              <el-input
                v-model="menuItem.sale"
                style="width: 95%"
                class="mt-2 mb-2"
                placeholder="Скидка"
                size="small"
                @input="changeCol"
              />
            </div>
          </div>
        </el-col>
        <el-col v-if="menuItem.ingredients" :span="24">
          <div class="link-ingredients">
            <el-button
              :icon="isShowIngredients ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              type="text"
              @click="showIngredients"
            >
              {{ isShowIngredients ? 'Скрыть' : 'Показать' }} список ингредиентов
            </el-button>
          </div>
          <div v-if="isShowIngredients" class="ingredients">
            <ol>
              <li v-for="ingredient in menuItem.ingredients" :key="ingredient.id">
                <span :class="{ exclude: ingredient.exclude }">
                  {{ ingredient.title }}
                  <span v-if="ingredient.replace"> -> {{ ingredient.replace }} </span>
                </span>
              </li>
            </ol>
          </div>
        </el-col>
      </el-row>
    </el-badge>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowIngredients: false,
      menuItem: this.menu,
    };
  },
  methods: {
    clickMe() {
      this.menuItem = { ...this.menuItem, isSelected: !this.menuItem.isSelected };
      this.$emit('selectMenu', this.menuItem);
    },
    changeCol() {
      this.$emit('selectMenu', this.menuItem);
    },
    showIngredients() {
      this.isShowIngredients = !this.isShowIngredients;
    },
  },
};
</script>
<style lang="scss" scoped>
.ingredients {
  padding-left: 50px;
  margin-bottom: 20px;
}
.link-ingredients {
  padding-left: 20px;
}
.exclude {
  color: #f56c6c;
  border-bottom: 3px dashed #f56c6c;
}
</style>
