<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="14" :lg="14" :md="24" :sm="24" :xs="24">
        <client-orders-calendar />
      </el-col>
      <el-col :span="10" :lg="10" :md="24" :sm="24" :xs="24">
        <client-orders-menu />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ClientOrdersMenu from './client-orders-menu/client-orders-menu.vue';
import ClientOrdersCalendar from './client-orders-calendar/client-orders-calendar.vue';

export default {
  components: { ClientOrdersMenu, ClientOrdersCalendar },
  data: () => ({
    value: new Date(),
    meals: [],
    modals: {
      create: {
        title: 'Добавить заказ',
        show: false,
      },
    },

    showAddOrder: false,
    showAddMenu: false,
    position: {},
    selectedDay: null,
  }),
  computed: { ...mapGetters({ menu: 'MENU', order: 'ORDER' }) },
  async destroyed() {
    this.$store.dispatch('RESET_MENU');
  },
  methods: {
    async createOrderPosition() {
      await this.$store.dispatch('STORE_ORDER', this.position);
      await this.$store.dispatch('FETCH_ORDER_BY_DATE', {
        date: this.selectedDay,
        client: this.$route.params.id,
      });
    },
    handleOpenMenu() {
      this.showAddMenu = true;
    },
  },
};
</script>
